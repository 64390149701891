import React from 'react';

class about extends React.Component {
  render() {
    return(
      <div class = "page">
        <h1>My Life</h1>
        <div class = "pictureContainer">
            <img class = "pic" src ="boo.jpg"/>
            <img class = "pic" src ="https://c8.alamy.com/comp/GFECA5/new-york-united-states-27th-july-2016-chef-guy-fieri-presented-designed-GFECA5.jpg"/>
            <img class = "pic" src ="https://c8.alamy.com/comp/GFECA5/new-york-united-states-27th-july-2016-chef-guy-fieri-presented-designed-GFECA5.jpg"/>
            <img class = "pic" src ="https://c8.alamy.com/comp/GFECA5/new-york-united-states-27th-july-2016-chef-guy-fieri-presented-designed-GFECA5.jpg"/>
            <img class = "pic" src ="https://c8.alamy.com/comp/GFECA5/new-york-united-states-27th-july-2016-chef-guy-fieri-presented-designed-GFECA5.jpg"/>
            <img class = "pic" src ="https://c8.alamy.com/comp/GFECA5/new-york-united-states-27th-july-2016-chef-guy-fieri-presented-designed-GFECA5.jpg"/>
        </div>
      </div>
    )
  }
}
export default about
